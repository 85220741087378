@mixin reset-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

@mixin horizontal-list {
    @include reset-list;

    li {
        display: inline-block;
        margin: {
            left: -2px;
            right: 2em;
        }
    }
}

@mixin reset-button {
    border: none;
    background-color: transparent;
    display: block;
    width: auto;
    padding: 0;
    cursor: pointer;
}