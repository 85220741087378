@import "../../../styles/settings";

.profile-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    font-weight: 700;
    padding-top: .125rem;
    text-transform: uppercase;
    background-color: $yellow-color;
    color: $black;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}