@import "../../styles/settings";

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(black, 0.6);
    opacity: 0;
    visibility: hidden;
    z-index: 20;
    transition: opacity 0.3s, visibility 0.3s, transform 0.3s;

    &.is-open {
        opacity: 1;
        visibility: visible;

        .modal {
            opacity: 1;
            transform: translateY(0);
        }
    }
}
.modal {
    position: relative;
    background-color: $white;
    overflow: auto;
    max-width: 100%;
    border-radius: $global-radius;
    opacity: 0;
    transform: translateY(-4rem);
}
.modal-close {
    display: inline-block;
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 0;
    width: 3rem;
    height: 3rem;
    border-radius: $global-radius;
    border: none;
    cursor: pointer;
    background-color: transparent;
    background-image: url('../../assets/images/icons/ic-cross-black.svg');
    background-size: 35% 35%;
    background-position: center;
    background-repeat: no-repeat;
    transition: background-color .3s;

    &:hover {
        background-color: $light-gray;
    }
}
.modal-content {
    padding: 3rem 3.5rem;
    width: 40rem;
    max-width: 100%;
    text-align: center;
}

@include small-only {
    .modal-backdrop {
        z-index: 10;
        transform: translateX(-4rem);

        &.is-open {
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
        }
    }
    .modal {
        margin: 0 !important;
        height: 100vh;
        border-radius: 0;
    }
    .modal-content {
        padding: 2rem 1.5rem;
        padding-top: 4rem;
        width: 100% !important;
    }
}

@include medium {
    .modal-backdrop {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .modal {
        margin: 1rem;
        border-radius: $global-radius;
        max-height: calc(100vh - 2rem);
        transition: opacity 0.3s, transform 0.3s;
        transition-delay: 0.2s;
    }
}
