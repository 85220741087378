@import '../../../styles/settings';

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 1.5rem;
    font-weight: 800;
    color: $dark-gray;
    padding-top: 3rem;
    padding-bottom: 6rem;
    cursor: progress;
}
.loader-spinner {
    display: inline-block;
    margin-bottom: 0.5rem;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    border: 0.5rem solid $light-gray;
    border-top-color: $yellow-color;
    animation: spinner 1s infinite;
}

.app-loader-spinner {
    display: inline-block;
    margin-bottom: 0.5rem;
    border-radius: 50%;
    width: 7rem;
    height: 7rem;
    border: 0.6rem solid $light-gray;
    border-top-color: $pink-color;
    animation: spinner 1s infinite;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
