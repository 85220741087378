@import "../../../styles/settings";

.mealplan-video {
    margin-top: 2.5rem;
    margin-bottom: 3rem;
    border-radius: $global-radius;
    overflow: hidden;
}
.mealplan-video-wrapper {
    display: flex;

    video {
        outline: none;
    }
}