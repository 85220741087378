@import "../../styles/settings";

@include medium {
    
    .profile-edit-form {
        .form-field-wrapper {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            & > .h-relative {
                flex-grow: 1;
            }
        }
        .form-label {
            width: 12rem;
            color: $medium-gray;
        }
        .form-error-messages {
            width: 100%;
        }
    }
    
}