@import '../../styles/settings';
@import '../../styles/mixins';

.fitness-programs-grid {
    display: grid;
    gap: 2rem;

    @include medium {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

.fitness-programs-questions {
    .dashboard-content {
        padding: 1.5rem 0;
        max-width: 550px;
        margin: 0 auto;
    }
}
.fitness-programs-question {
    padding: 2rem;
    margin-bottom: 1.5rem;
    border-radius: calc(#{$global-radius} / 2);
    border: 1px solid $lightest-gray;

    h3 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1.5rem;
    }
}
.fitness-programs-confirm {
    gap: 0.85rem;
    display: flex;
    align-items: center;
    flex-direction: column;

    & > * {
        min-width: 18rem;
    }
}
