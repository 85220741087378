@import '../../../styles/settings';

$training-colors: (
    'yellow': $yellow-color,
    'pink': $pink-color,
    'blue': $blue-color,
    'purple': $purple-color,
    'gray': $medium-gray,
    'grey': $medium-gray,
    'black': $black,
);

.training-title-wrapper {
    background-color: $yellow-color;
    overflow: hidden;

    .tagline {
        margin-bottom: 0.5rem;
    }

    @each $name, $color in $training-colors {
        &.is-#{$name} {
            background-color: $color;
        }
    }

    &.is-gray,
    &.is-black {
        color: $white;

        h1 {
            color: $white;
        }

        .training-title-tag {
            background-color: rgba($white, 0.1);

            svg {
                stroke: $white;
            }
        }

        .back-button {
            color: $white;

            svg {
                stroke: $white;
            }
        }
    }
}
.training-title {
    position: relative;
    padding: 5rem 0;
    padding-top: 6.5rem;
    z-index: 1;

    .back-button {
        position: absolute;
        top: 1.5rem;
        left: 0;
    }
    .button {
        vertical-align: top;
    }
}

.training-title-image {
    position: absolute;
    bottom: 0;
    right: 5%;
    max-height: 90%;
    z-index: -1;
}

.training-title-back-text {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translateY(-50%);
    font-size: 27rem;
    font-family: 'Caveat Brush';
    color: rgba($white, 0.3);
    white-space: nowrap;
    z-index: -1;
}

.training-title-tag {
    display: inline-flex;
    vertical-align: top;
    font-size: 0.875rem;
    align-items: center;
    line-height: 1;
    background-color: rgba($black, 0.1);
    padding: 0.875rem 1.375rem;
    border-radius: $global-radius;

    svg {
        display: inline-block;
        margin-right: 0.5rem;
        stroke: $black;
        width: 1.125rem;
        height: 1.125rem;
    }

    strong {
        margin-right: 0.75rem;
    }

    @include small-only {
        background-color: rgba($white, 0.75);
    }
}

@include small-only {
    .training-title {
        padding: 3.5rem 0;
        padding-top: 4.5rem;

        .button {
            margin-bottom: 1rem;
        }
    }
    .training-title-image {
        right: -10%;
    }
    .training-title-back-text {
        font-size: 10rem;
    }
}

@include large {
    .training-title-image {
        right: 20%;
    }
}
