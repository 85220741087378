@import '../../../styles/settings';

.exercise-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    font-size: 3rem;
    font-weight: 800;
    z-index: 3;
}

.exercise-preview-title {
    text-transform: uppercase;
    margin: 0 auto;
    font-size: 3rem;
    max-width: 20rem;
    font-weight: 800;
    margin-bottom: 0.5rem;
    color: $white;
    @include medium-down {
        font-size: 1.25rem;
    }
    &.is-small {
        font-size: 1.25rem;
    }
}

.exercise-preview-name {
    @include medium-down {
        font-size: 1rem;
    }
    font-size: 1.75rem;
    font-weight: 700;
    color: $white;
    margin: 0 auto;
    max-width: 20rem;
}
.exercise-preview-progress {
    display: inline-block;
    margin-top: 2rem;
    width: 50%;
    @include medium-down {
        width: 5rem;
        margin-top: 1rem;
    }
}
