@import '../../styles/settings';
@import '../../styles/mixins';

.profile-avatar-wrapper {
    position: relative;
    display: inline-block;
    margin-bottom: 1.5rem;
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 50%;
    // overflow: hidden;
    box-shadow: 0px 15px 50px rgba(47, 51, 69, 0.1);
}
.profile-avatar-edit {
    @include reset-button;
    position: absolute;
    bottom: 0;
    right: -.5rem;
    width: 2.25rem;
    height: 2.25rem;
    background-color: $black;
    border-radius: 50%;
    border: 3px solid $white;

    svg {
        stroke: $semi-gray;
        transition: stroke .3s;
    }

    &:hover {
        svg {
            stroke: $white;
        }
    }
}
.profile-title {
    margin-bottom: .5rem;
}
.profile-subtitle {
    font-size: .875rem;
    margin-bottom: 1.5rem;
}
.profile-controls {
    margin-top: 3rem;
    padding-top: 1.5rem;
    border-top: 1px solid $light-gray;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@include medium-down {
    
    .profile {
        .dashboard-side-text {
            width: 100%;
            text-align: center;
        }
    }
    .profile-controls {
        margin-bottom: 2rem;
    }
    
}

@include large {
    
    .profile {
        .dashboard-side-text {
            width: 20rem;
        }
        .dashboard-content {
            width: calc(100% - 20rem);
        }
    }
    
}