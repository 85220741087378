@import '../../../styles/settings';

.accordion {
    position: relative;
    overflow: hidden;
    height: 0;
    transition: height 0.3s;

    &:not(.has-init-height) {
        &.is-open {
            & > div {
                visibility: visible;
                opacity: 1;
                transform: translateY(0);
            }
        }
        & > div {
            visibility: hidden;
            opacity: 0;
            transform: translateY(-1rem);
            transition: transform 0.3s, opacity 0.3s, visibility 0.3s;
        }
    }
}
