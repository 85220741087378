@import "../../../styles/settings";

$button-colors: ("yellow": $yellow-color, "pink": $pink-color, "blue": $blue-color, "purple": $purple-color, "gray": $dark-gray, "white": $white, "black": $black, "alert": $alert-color, "warning": $warning-color);

.button {
    display: inline-block;
    font-size: .875rem;
    font-weight: 800;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    white-space: nowrap;
    color: $black;
    line-height: 1.2;
    padding: 1.375rem 1.875rem;
    border: none;
    border-radius: $global-radius;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color .3s, color .3s, box-shadow .3s;

    i {
        display: inline-block;
        vertical-align: middle;
        width: 1.125rem;
        height: 1.125rem;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin-left: .75rem;
    }

    @each $name, $color in $button-colors {
        &.is-#{$name} {
            background-color: $color;
            
            &:hover:not([disabled]):not(.is-loading) {
                background-color: darken($color, 10%);
            }
        }
    }
    
    &.is-gray, &.is-black, &.is-alert, &.is-warning  {
        color: $white;

        &.is-loading {
            &::after {
                border: 4px solid rgba($white, .5);
                border-top-color: $white;
            }
        }
    }

    &.is-small {
        padding: .875rem 1.375rem;
        text-transform: none;
        letter-spacing: 0;
    }

    &.is-link {
        padding: 0;
        text-transform: none;
        letter-spacing: 0;
        color: $medium-gray;
        background-color: transparent;
        box-shadow: none;

        &:hover:not([disabled]):not(.is-loading) {
            color: $black;
            background-color: transparent;
        }
    }

    &:hover {
        box-shadow: none;
    }

    &[disabled] {
        opacity: .5;
        cursor: not-allowed;
        box-shadow: none;
    }

    &.is-loading {
        position: relative;
        color: transparent !important;

        i {
            opacity: 0;
        }

        &::after {
            content: '';
            position: absolute;
            top: calc(50% - 1rem);
            left: calc(50% - 1rem);
            width: 2rem;
            border-radius: 50%;
            height: 2rem;
            border: 4px solid rgba($black, .5);
            border-top-color: $black;
            animation: spinning 1s linear infinite;
        }
    }
}

@keyframes spinning {
    0% {
        transform: rotate(0deg);
    } 100% {
        transform: rotate(360deg);
    }
}

@include small-only {
    
    .button {
        letter-spacing: 0;
    }
    
}