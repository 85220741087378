@import "../../styles/settings";

.shopping-list-header {
    margin-bottom: 2.5rem;
}

@include medium {
    
    .shopping-list-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    
        & > * {
            flex-basis: 100%;
        }

        .tagline {
            text-align: center;
        }
        .shopping-list-header-controls {
            text-align: right;
        }
    }
    
}

@include small-only {
    
    .shopping-list-header {
        .tagline, .shopping-list-header-controls {
            text-align: center;
        }
        .tagline {
            margin: 1rem 0;
        }
    }
    
}