@import "../../../../styles/settings";

.form-password-show {
    display: inline-block;
    position: absolute;
    top: calc(50% - 1rem);
    right: 1rem;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    font-size: 0;
    border: none;
    background-color: transparent;
    background-size: 50% 50%;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: background-color .3s;

    &:hover {
        background-color: $light-gray;
    }
}

::placeholder {
    font-weight: 400;
}
