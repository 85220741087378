@import '../../styles/settings';

.training-title-toggle {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid $light-gray;
    cursor: pointer;

    & > svg {
        transition: transform 0.3s;
    }

    &.is-open {
        & > svg {
            transform: rotate(180deg);
        }
    }
    &.is-title {
        cursor: default;
    }
}
