$names: abs-black, timer-black, running-machine-black, play-black, dumbbells-black, knife-fork-black, calendar-1-black,
    calendar-7-black, list-black, wallet-black, person-circle-black, download-white, play-white, meat-pink,
    vegetable-pink, drinks-purple, legumes-purple, fat-blue, milk-blue, carbs-yellow, fruit-yellow, eye-gray,
    eye-crossed-gray, phone-download, cross-black;

.icon {
    @each $name in $names {
        &-#{$name} {
            background-image: url('../../assets/images/icons/ic-#{$name}.svg');
        }
    }
}
