@import '../../../styles/settings';

$illustration-colors: (
    'yellow': $yellow-color,
    'pink': $pink-color,
    'blue': $blue-color,
    'purple': $purple-color,
);
$coaches: 'katy', 'domi', 'marti', 'reni';

.auth-wrapper-illustration {
    position: relative;
    background-color: $yellow-color;
    transition: background-color .5s;

    @each $name, $color in $illustration-colors {
        &.is-#{$name} {
            background-color: $color;
        }
    }
}
.auth-wrapper-main {
    padding: 3rem 0;
}

.auth-wrapper-topbar {
    background-color: $lightest-gray;
    padding: 1.375rem 0;

    & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
.auth-wrapper-logo {
    display: block;
    max-width: 13.5rem;
    margin-right: auto;
}
.auth-wrapper-tagline {
    color: $medium-gray;
    font-size: 0.875rem;
    margin-left: 1rem;
}
.auth-wrapper-content {
    background-color: $black;
}
.auth-wrapper-main {
    background-color: $white;
}

@include small-only {
    
    .auth-wrapper-logo {
        max-width: 10rem;
    }
    
}

@include medium-down {
    
    .auth-wrapper-content {
        min-height: 100vh;
    }
    .auth-wrapper-main {
        text-align: center;
    }
    
}

@include large {
    .auth-wrapper {
        display: flex;
        flex-wrap: wrap;
        min-height: 100vh;
        width: 100%;

        & > div {
            width: 50%;
        }
    }
    .auth-wrapper-main {
        padding: 3.75rem 0 5rem 0;
    }
    .auth-wrapper-illustration {
        position: relative;
        background-image: url('../../../assets/images/backgrounds/katy.png');
        background-size: auto 90%;;
        background-position: center bottom;
        background-repeat: no-repeat;

        @each $coach in $coaches {
            &[coach-image="#{$coach}"] {
                background-image: url('../../../assets/images/backgrounds/#{$coach}.png');
            }
        }

        &.is-login-illustration {
            &::after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                right: 5%;
                left: 5%;
                background-image: url('../../../assets/images/backgrounds/login-bubbles.svg');
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
    }
}
