@import '../../../styles/settings';

$card-colors: (
    'yellow': $yellow-color,
    'pink': $pink-color,
    'blue': $blue-color,
    'purple': $purple-color,
    'gray': $medium-gray,
    'black': $black,
);

.training-card {
    position: relative;
    display: block;
    margin-bottom: 1.5rem;

    &:hover {
        .training-card-text {
            transform: scale(1.3);
        }
    }

    @each $name, $color in $card-colors {
        &.is-#{$name} {
            .training-card-square {
                background-color: $color;
            }
        }
    }
}
.training-card-square {
    border-radius: $global-radius;
    overflow: hidden;
    position: relative;
    padding-bottom: 100%;
    background-color: $yellow-color;
}
.training-card-content {
    display: flex;
    align-items: flex-end;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 1.125rem;
}
.training-card-image {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-size: contain;
    background-position: bottom center;
    background-repeat: no-repeat;
}
.training-card-text {
    position: absolute;
    top: 1.5rem;
    bottom: 0;
    right: 0;
    left: 0;
    font-size: 8rem;
    font-family: 'Caveat Brush';
    text-align: center;
    color: rgba($white, 0.3);
    transition: transform 0.3s;
}
.training-card-button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 1.25rem;
    font-weight: 800;
    padding: 0.875rem 1.75rem;
    background-color: $white;

    & > div {
        min-width: 0;
    }

    i {
        display: inline-block;
        margin-right: 0.75rem;
        min-width: 2rem;
        height: 2rem;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
}

@include small-only {
    .training-card-square {
        border-radius: calc(#{$global-radius} / 1.25);
    }
    .training-card-button {
        padding: 0.5rem 0.25rem;
        font-size: 1.125rem;

        i {
            margin-right: 0.5rem;
            min-width: 1.5rem;
            height: 1.5rem;
        }
    }
    .training-card-text {
        font-size: 5.5rem;
    }
}

@include medium {
    .training-card-square {
        box-shadow: 0px 15px 50px rgba(47, 51, 69, 0.1);
    }
    .training-card-button {
        position: absolute;
        bottom: 1rem;
        left: 1.5rem;
        right: 1.5rem;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
        border-radius: $global-radius;
        width: calc(100% - 3rem);
        cursor: pointer;
    }
}
