@import "../../styles/settings";

.color-box {
    display: inline-block;
    vertical-align: top;
    width: 8rem;
    height: 8rem;
    padding: 2.625rem 0;
    margin: 0 1rem 1rem 0;
    text-align: center;
    color: $white;
    box-shadow: 0 0 1rem rgba($black, .2);

    & > div:first-child {
        font-weight: 700;
        text-transform: uppercase;
        font-size: .875rem;
    }
}