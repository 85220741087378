@import '../../styles/settings';

.app-banner-wrapper {
    background-color: $pink-color;
    padding: 0.625rem 0.9375rem;
    display: flex;
    align-items: start;
}

.app-banner-content {
    max-width: 88.75rem;
    margin: 0 auto;
    color: $black;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}

.app-banner-text {
    display: flex;
    gap: 0.75rem;
    align-items: center;
    text-align: center;
}

.icon-phone-download {
    width: 2.625rem;
    height: 2.625rem;
    display: block;
}

.app-banner-icon-wrapper {
    width: 2.625rem;
    height: 2.625rem;
}

.app-banner-stores {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.app-banner-store-badge {
    height: 2.875rem;
}

.icon-cross-black {
    width: 1.25rem;
    height: 1.25rem;
    display: block;
}

.app-banner-close {
    margin-left: 1rem;
    cursor: pointer;
    background-color: transparent;
    border: none;
}

.app-banner-close-mobile {
    display: block;
    margin-top: 0.25rem;
}

.app-banner-close-desktop {
    display: none;
}

@include large {
    .app-banner-wrapper {
        display: block;
    }

    .app-banner-content {
        flex-direction: row;
    }

    .app-banner-close-mobile {
        display: none;
    }

    .app-banner-close-desktop {
        display: block;
    }
}
