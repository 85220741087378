@import "../../../styles/settings";

.exercise-break {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    font-size: 3rem;
    font-weight: 800;
    background-color: $yellow-color;
    z-index: 3;
}
.exercise-break-progress {
    width: 30%;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 10%;
        bottom: 10%;
        right: 10%;
        left: 10%;
        border-radius: 50%;
        border: 2px solid rgba($white, .3);
        border-top-color: transparent;
        animation: spinning-progress 5s linear infinite;
    }
}
.exercise-break-counter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    font-size: 35vw;
    font-family: 'Caveat Brush', serif;
    color: rgba($white, .15);
    z-index: 0;
}

@media screen and (max-width: 1024px) {
    .exercise-break-counter {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        font-size: 30vw;
        font-family: 'Montserrat', serif;
        color: rgba($white, .15);
        z-index: 0;
    }
}

@keyframes spinning-progress {
    0% {
        transform: rotate(0deg);
    } 100% {
        transform: rotate(360deg);
    }
}