@import '../../../styles/settings';

.number-input-wrapper {
    position: relative;
    margin-bottom: 1rem;
    display: flex;

    &.is-pink {
        input {
            &:focus {
                border-color: $pink-color;
            }
        }

        .number-input-checkmark {
            &.is-correct {
                &::after {
                    background-image: url('../../../assets/images/icons/ic-check-pink.svg');
                }
            }
        }
    }

    &.hide-checkmark {
        .number-input-checkmark.is-correct {
            display: none;
        }
    }
}
.number-input-container {
    flex-grow: 1;
    position: relative;

    input {
        width: 100%;
        padding-right: 4rem;
    }
}
.number-input-unit {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1.5rem;
    font-size: 1rem;
    font-weight: 600;
}
.number-input-checkmark {
    position: relative;
    min-width: 4rem;

    &::after {
        content: '';
        position: absolute;
        top: calc(50% - 0.5rem);
        right: calc(50% - 0.5rem);
        width: 1rem;
        height: 1rem;
        opacity: 0;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transition: opacity 0.3s;
    }

    &.is-correct {
        &::after {
            background-image: url('../../../assets/images/icons/ic-check-yellow.svg');
            opacity: 1;
        }
    }
    &.is-incorrect {
        &::after {
            background-image: url('../../../assets/images/icons/ic-cross-alert.svg');
            opacity: 1;
        }
    }
}
.number-input-tooltip {
    position: absolute;
    bottom: 90%;
    font-size: 0.875rem;
    background-color: $alert-color;
    color: $white;
    border-radius: 1rem;
    padding: 0.75rem 1.25rem;
    width: auto;
    right: 0;
    text-align: right;
    white-space: nowrap;

    &::after {
        content: '';
        position: absolute;
        top: 100%;
        right: 1.625rem;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 0.375rem 0 0.375rem;
        border-color: $alert-color transparent transparent transparent;
    }
}
