@import "../../../styles/settings";

.fake-password-input {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: $lightest-gray;
    color: $black;
    border: 1px solid $semi-gray;
    font-size: 1rem;
    font-weight: 700;
    padding: 1.125rem 1.375rem;
}