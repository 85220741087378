@import '../../../../styles/settings';

.profile-toggle {
    display: flex;
    align-items: center;
    position: relative;
    background-color: transparent;
    margin-left: 1.5rem;
    border: none;
    padding: 0.5rem;
    padding-right: 1rem;
    white-space: nowrap;
    border-radius: $global-radius;
    font-size: 1rem;
    font-weight: 700;
    transition: background-color 0.3s;
    cursor: pointer;

    &::before {
        content: '';
        position: absolute;
        top: calc(50% - 1rem);
        left: -1.5rem;
        width: 1px;
        height: 2rem;
        background-color: $light-gray;
    }
}

.profile-toggle-avatar {
    min-width: 2.75rem;
    width: 2.75rem;
    height: 2.75rem;
    margin-right: 0.75rem;
    overflow: hidden;
    border-radius: 50%;
}

@include medium-down {
    .profile-toggle {
        padding: 0;
        font-size: 0.75rem;
        margin-left: 2rem;

        &::before {
            left: -0.75rem;
        }
    }
    .profile-toggle-avatar {
        order: 1;
        margin: 0;
        margin-left: 0.75rem;
        min-width: 2.25rem;
        min-height: 2.25rem;
    }
}

@include large {
    .profile-toggle {
        &:hover {
            background-color: $lightest-gray;
        }
    }
}
