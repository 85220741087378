@import "../../../styles/settings";

.meal-plan-overview {
    display: flex;
    align-items: center;
    border-radius: $global-radius;
    padding: 1.25rem;
    margin-bottom: 1.25rem;
    background-color: rgba($lightest-gray, .6);
}

.meal-plan-overview-title {
    display: flex;
    align-items: flex-end;
    padding: 1.125rem 1.5rem;
    border-radius: $global-radius;
    background-color: $white;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    
    .icon-title {
        position: relative;
        margin-bottom: 0;
        margin-right: 3rem;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            right: -1.5rem;
            width: 1px;
            background-color: $semi-gray;
        }
    }
}
.meal-plan-overview-title-values {
    line-height: 1.2;
    font-size: 1.125rem;
}
.meal-plan-overview-values {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    margin: 0 1.5rem;
    
    .icon-title {
        position: relative;
        margin-bottom: 0;

        i {
            margin-right: .5rem;
        }
        div {
            font-weight: 400;
        }
    }
    .vertical-line {
        width: 1px;
        background-color: $semi-gray;
    }
}

@include medium-down {
    
    .meal-plan-overview {
        display: block;
    }
    .meal-plan-overview-title {
        justify-content: space-between;
        margin-bottom: 1.5rem;
        .icon-title {
            margin-right: 1.5rem;
            i {
                display: none;
            }
            &::after {
                content: none;
            }
        }
    }
    
}

@include small-only {
    
    .meal-plan-overview {
        margin-bottom: 3rem;
    }
    .meal-plan-overview-values {
        margin: 0 1rem;

        .icon-title {
            display: block;
            text-align: center;

            h3 {
                font-size: 1rem;
            }
            i {
                display: inline-block;
                margin: 0;
            }
        }
    }
    
}