@import '../settings';
@import '../mixins';
@import 'flex-grid';
@import '../general/fonts';
@import '../general/icons';
@import '../general/skeletons';

html {
    box-sizing: border-box;
    font-size: 100%;
}

body {
    margin: 0;
    padding: 0;
    background: $body-background;
    font-family: $body-font-family;
    font-weight: 400;
    line-height: $global-lineheight;
    color: $body-color;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

img {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    height: auto;
    -ms-interpolation-mode: bicubic;
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    outline: none;
}

hr {
    border: none;
    height: 1px;
    background-color: $light-gray;
    margin: 1rem 0;
}

/* --------------
- Typography
----------------- */
h1,
h2,
h3,
h4,
h5,
h6 {
    color: $header-color;
    font-family: $header-font-family;
    font-weight: $header-font-weight;
    line-height: $header-lineheight;
    font-size: 1rem;
    margin-top: 0;
    margin-bottom: 1rem;
    text-transform: none;
}

h1 {
    margin-bottom: 2rem;
}

h3 {
    margin-bottom: 0.75rem;
}

$headers-map: (
    'h1': $header-h1-fontsize,
    'h2': $header-h2-fontsize,
    'h3': $header-h3-fontsize,
    'h4': $header-h4-fontsize,
);

@include large {
    @each $header, $style in $headers-map {
        #{$header} {
            font-size: map-get($style, 'large') / 16 * 1rem;
        }
    }
}
@include medium-only {
    @each $header, $style in $headers-map {
        #{$header} {
            font-size: map-get($style, 'medium') / 16 * 1rem;
        }
    }
}
@include small-only {
    @each $header, $style in $headers-map {
        #{$header} {
            font-size: map-get($style, 'small') / 16 * 1rem;
        }
    }
}

a {
    text-decoration: none;
    color: inherit;
}

p {
    margin-top: 0;
    color: $body-color;
    line-height: $global-lineheight;

    a {
        color: $black;
        text-decoration: underline;

        &:hover {
            color: $dark-gray;
        }
    }
}

/* --------------
- Layout
----------------- */
.page-row {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
}
.container-small-gutter {
    & > div {
        padding: 0 0.25rem;
    }
}

/* --------------
- Inputs
----------------- */
input[type='text'],
input[type='password'],
input[type='number'],
.form-select-value {
    display: block;
    width: 100%;
    background-color: $lightest-gray;
    color: $black;
    line-height: normal;
    border: 1px solid $semi-gray;
    font-size: 1rem;
    font-weight: 700;
    padding: 1.125rem 1.375rem;

    &:focus {
        border-color: $yellow-color;
    }
}

input[type='file'] {
    display: none;
}

input[type='number'] {
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

#chat-application,
#smartsupp-widget-container {
    display: none !important;
}

body[route='/dashboard/profil/'],
body[route='/app/chat']  {
    #chat-application,
    #smartsupp-widget-container {
        display: block !important;
        z-index: 19 !important;
    }
}
