@import '../../../styles/settings';

$pager-colors: (
    'yellow': $yellow-color,
    'pink': $pink-color,
    'blue': $blue-color,
    'purple': $purple-color,
);

.pager-item {
    display: inline-block;
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 50%;
    text-align: center;
    font-weight: 800;
    font-size: 0.75rem;
    line-height: 1.875rem;
    transform: scale(0.4);
    transition: transform 0.3s, color 0.3s;
    cursor: default;

    @each $name, $color in $pager-colors {
        &.is-#{$name} {
            background-color: $color;
            color: $color;
        }
    }

    &.is-active {
        color: $white;
        transform: scale(1);
    }
}
