@import '../../../styles/settings';
@import '../../../styles/mixins';

.recipe-card {
    background-color: $white;
    box-shadow: 0px 15px 50px rgba(47, 51, 69, 0.1);
    border-radius: calc(#{$global-radius} / 3 * 2);
    overflow: hidden;
}

.recipe-card-image {
    padding-bottom: 50%;
    background-color: $light-gray;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.recipe-card-content-box {
    padding: 1.875rem 1.75rem;

    ul {
        @include reset-list;

        li {
            margin-bottom: 0.5rem;
            font-size: 0.875rem;
        }
    }
}

@include small-only {
    .recipe-card-content-box {
        &:not(:last-child) {
            border-bottom: 1px solid $light-gray;
        }
    }
}

@include large {
    .recipe-card {
        border-radius: $global-radius;
    }
    .recipe-card-content {
        display: flex;
    }
    .recipe-card-content-box {
        flex: 1;

        &:not(:last-child) {
            border-right: 1px solid $light-gray;
        }

        ul > li {
            position: relative;
            span {
                position: absolute;
                top: 0;
                left: 0;
            }

            &::before {
                content: attr(size-content);
                display: block;
                font-weight: bold;
                overflow: hidden;
                visibility: hidden;
            }
        }
    }
}
