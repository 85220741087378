@import "../../../styles/settings";
@import "../../../styles/mixins";

.news-carousel {
    position: relative;
    // margin: 0  -.75rem;
    margin-bottom: 1rem;
    padding: 0 2.5rem;
    overflow: hidden;

    .slick-list {
        transition: height .3s;
    }

    .slick-arrow {
        @include reset-button;
        display: inline-block;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        font-size: 0;
        position: absolute;
        top: calc(50% - 1rem);
        background-size: 50% 50%;
        background-position: center;
        background-repeat: no-repeat;
        transition: background-color .3s;

        &.slick-prev {
            left: .125rem;
            background-image: url('../../../assets/images/icons/ic-chevron-left-black.svg');
        }
        &.slick-next {
            right: .125rem;
            background-image: url('../../../assets/images/icons/ic-chevron-right-black.svg');
        }
        &.slick-disabled {
            opacity: .3;
            cursor: not-allowed;
        }

        &:hover {
            background-color: $light-gray;
        }
    }
}

@include medium {
    
    .news-carousel {
        padding: 0 4rem;

        .slick-arrow {
            &.slick-prev {
                left: 0.9375rem;
            }
            &.slick-next {
                right: 0.9375rem;
            }
        }
    }
    .overview-news-item {
        margin: 0 .75rem;
        width: calc(100% - 1.5rem) !important;
    }
    
}