@import '../../../styles/settings';
@import '../../../styles/mixins';

.recipe-list-item {
    background-color: $white;
    display: inline-block;
    margin: 0 0 2.5rem;
    width: 100%;
    will-change: transform;

    table {
        width: 100%;
        text-align: left;
        border-collapse: collapse;

        th {
            font-size: 0.875rem;
            padding: 0.125rem 0;
        }

        th:first-of-type {
            width: 100%;
            padding-right: 1.5rem;
            font-weight: 400;
        }
        th:nth-of-type(2) {
            white-space: nowrap;
            font-weight: 700;
        }

        &.is-bordered {
            tr:not(:last-child) {
                border-bottom: 1px solid $light-gray;
            }
            th {
                padding-top: 0.875rem;
                padding-bottom: 0.875rem;
            }
        }
    }
}

@include small-only {
    .recipe-list-item {
        padding-bottom: 1.25rem;
        margin-bottom: 1.25rem;
        
        &:not(:last-child) {
            border-bottom: 1px solid $light-gray;
        }
    }
}

@include medium {
    .recipe-list {
        column-count: 3;
        border-radius: calc(#{$global-radius} / 3 * 2);
    }
    .recipe-list-item {
        border-radius: $global-radius;
        padding: 2rem 2.5rem;
        margin: 0 0 1rem;
        box-shadow: 0px 15px 50px rgba(47, 51, 69, 0.1);
    }
}
