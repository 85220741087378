@import '../../../styles/settings';
@import '../../../styles/mixins';

$nav-colors: (
    'yellow': $yellow-color,
    'pink': $pink-color,
    'blue': $blue-color,
);

.topbar {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2.5rem;
    background-color: $white;
    border-bottom: 1px solid $light-gray;
    z-index: 10;
}
.topbar-logo {
    max-width: 13.5rem;
    margin-right: auto;
}

.topbar-nav {
    @include reset-list;

    li {
        display: inline-block;
    }
}

@include medium-down {
    .topbar {
        padding: 1rem;
    }
    .topbar-nav {
        display: flex;
        position: fixed;
        justify-content: space-evenly;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $white;
        border-top: 1px solid $light-gray;
        padding: 0.5rem 0;
        font-size: 0.75rem;
        font-weight: 500;

        li {
            flex-grow: 1;
            text-align: center;
        }
    }
    .topbar-nav-item {
        display: inline-block;
        text-align: center;
        color: $medium-gray;
        width: 80%;
        transition: color 0.3s;

        &.is-active {
            color: $black;
        }

        @each $name, $color in $nav-colors {
            &.is-#{$name} {
                &.is-active {
                    .topbar-nav-item-icon {
                        background-color: $color;

                        svg {
                            fill: $black;
                        }
                    }
                }
                .topbar-nav-item-icon {
                    background-color: rgba($color, 0.1);

                    svg {
                        fill: $color;
                    }
                }
            }
        }
    }
    .topbar-nav-item-icon {
        display: inline-block;
        width: 1.75rem;
        height: 1.75rem;
        text-align: center;
        border-radius: 50%;
        margin-bottom: 0.125rem;

        svg {
            width: 1.125rem;
            height: 1.125rem;
            margin-top: rem-calc(5);
        }
    }
}

@include large {
    .topbar {
        &.is-scrolled {
            .topbar-nav-item {
                padding-top: 1.5rem;
                padding-bottom: 1.5rem;
            }
        }
    }
    .topbar-nav-item {
        display: inline-block;
        position: relative;
        font-size: 0.875rem;
        padding: 2.5rem 1.5rem;
        text-align: center;
        transition: font-weight 0.2s, padding 0.3s;

        &.is-active {
            font-weight: 800;

            &::after {
                height: 4px;
            }
        }

        &:hover {
            font-weight: 800;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            height: 0;
            transition: height 0.3s;
        }

        &::before {
            content: attr(title);
            display: block;
            font-weight: 800;
            height: 0;
            overflow: hidden;
            visibility: hidden;
        }

        @each $name, $color in $nav-colors {
            &.is-#{$name} {
                &.is-active {
                    background-color: rgba($color, 0.3);

                    &::after {
                        background-color: $color;
                    }
                }
                &:hover {
                    background-color: rgba($color, 0.3);
                }
            }
        }
    }
}
