@import '../../styles/settings';
@import '../../styles/mixins';

.fitness-plan .dashboard-content {
    padding: 1.5rem 0;
    max-width: 650px;
    margin: 0 auto;
    overflow: auto;
}

.fitness-plan-buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
    @include small-only {
        justify-content: center;
    }
}

.fitness-plan-week-select {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    font-size: 1.125rem;
    font-weight: 700;
    @include small-only {
        justify-content: center;
    }

    button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: $lightest-gray;
        width: 2.25rem;
        height: 2.25rem;
        border-radius: 100%;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s;
        &:hover {
            background-color: $yellow-color;
        }
    }
}

.fitness-plan-item {
    display: flex;
    align-items: center;
    padding: 1rem 0;
    border-bottom: 1px solid $light-gray;
    gap: 1.5rem;
    @include small-only {
      align-items: flex-start;
    }
}
.fitness-plan-item-day {
    font-size: 1.25rem;
    @include small-only {
      font-size: 1.5rem;
    }
    font-weight: 700;
    width: 2rem;
    line-height: 1;
    white-space: nowrap;
    text-align: center;
}
.fitness-plan-item-day-date {
    color: $medium-gray;
    font-size: 1.125rem;
    @include small-only {
        font-size: 1.5rem;
    }
    font-weight: 600;
    width: auto;
    line-height: 1;
    white-space: nowrap;
    margin-top: 0.5rem;
}

.fitness-plan-item-container {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    @include small-only {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
    }
}

.fitness-plan-date-wrapper {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    padding-right: 2rem;
}

.fitness-plan-stone {
    display: inline-flex;
    align-items: center;
    gap: 0.75rem;
    font-size: 1.25rem;
    font-weight: 700;
    border-radius: 50px;
    padding: 0.625rem 0.625rem 0.625rem 1.25rem;
    transform: translate(0, 0);

    @include small-only {
      font-size: 1rem;

      &+.fitness-plan-stone-placeholder {
        display: none;
      }
    }

    & > * {
        display: inline-flex;
        align-items: center;
        flex-shrink: 0;
        justify-content: center;
        width: 2rem;
        height: 2rem;
        @include small-only {
          width: 1.5rem;
          height: 1.5rem
        }
        background-color: $black;
        border-radius: 100%;
        cursor: pointer;
        border: 1px solid transparent;
        transition: background-color 0.3s;

        svg {
            width: 1rem;
            flex-shrink: 0;
            color: $white;
            @include small-only {
              width: 0.75rem;
            }
        }
    }

    &:not(.is-done) {
        & > * {
            opacity: 0.25;
        }
    }
    &.is-done {
        & > * {
            background-color: $success-color;
        }
    }

    &.is-yellow {
        background-color: $yellow-color;
    }
    &.is-pink {
        background-color: $pink-color;
    }
    &.is-blue {
        background-color: $blue-color;
    }
    &.is-purple {
        background-color: $purple-color;
    }
    &.is-black {
        background-color: $black;
        color: $white;
    }
    &.is-grey,
    &.is-gray {
        background-color: $medium-gray;
        color: $white;
        span {
            & > svg {
                color: $medium-gray;
            }
        }
    }
}
.fitness-plan-stone-placeholder {
    width: 7rem;
    height: 3.25rem;
    border-radius: 999px;
    border: 1px dashed transparent;
    transition: height 0.3s, margin 0.3s;

    &.is-dragging {
        border-color: $medium-gray;
    }

    @include small-only {
      height: 0;
      &.is-dragging {
        height: 2.75rem;
      }
    }
}
