@import '../../styles/settings';
@import '../../styles/mixins';
@import './VideoThumb.scss';

$video-colors: (
    'yellow': $yellow-color,
    'pink': $pink-color,
    'blue': $blue-color,
    'purple': $purple-color,
    'gray': $dark-gray,
    'grey': $dark-gray,
    'black': $black,
);

.video-container {
    visibility: hidden;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 20;

    &.is-open {
        visibility: visible;

        .video-backdrop {
            opacity: 1;
        }
        .video-wrapper {
            transform: translate(-50%, -50%) scale(1);
            opacity: 1;
        }
    }

    &.is-fullscreen {
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        transform: none;
        background-color: #000;

        .video-wrapper {
            border-radius: 0;
            &:not(.is-vertical) {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                width: 100%;
                transform: none;
            }

            video {
                max-width: none;
                margin-left: 50%;
                transform: translateX(-50%);
            }
        }
    }
}
.video-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: $global-radius;
    // overflow: hidden;
    transform: translate(-50%, -50%) scale(0.8);
    width: 70%;
    opacity: 0;
    transition: opacity 0.3s;

    .video {
        display: flex;
    }

    video {
        outline: none;
        max-width: 100vw;
        max-height: 100vh;
    }

    &.is-flash::after {
        opacity: 1;
        visibility: visible;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        opacity: 0;
        visibility: hidden;
        z-index: 8;
    }

    &.is-sequence::after {
        background-color: #000;
        transition: opacity 0.3s, visibility 0.3s;
    }

    @each $name, $color in $video-colors {
        &.is-#{$name} {
            // background-color: lighten($color, 10%);
            background-color: #000 !important;

            .exercise-preview {
                background-color: rgba($color, 0.3);
            }
            .video-notes-sets {
                background-color: rgba($color, 0.5);
            }
            .exercise-break {
                background-color: rgba($color, 1);
            }
            .video-play-button {
                &::after {
                    background-color: rgba($color, 0.375);
                }
                &:hover::after {
                    background-color: rgba($color, 0.5);
                }
            }
            .video-seeker {
                &::-webkit-slider-thumb {
                    background: darken($color, 20%) !important;
                }
                &::-ms-thumb {
                    background: darken($color, 20%) !important;
                }
                &::-moz-range-thumb {
                    background: darken($color, 20%) !important;
                }
            }
            .video-seeker-track-played {
                background-color: $color;
            }
        }
    }

    &.is-yellow {
        .video-notes-sets {
            color: $black;
        }
    }

    &.is-vertical {
        width: auto;
        height: 100%;
        max-width: 100%;
    }
}

.video-seeker-wrapper {
    display: flex;
    position: absolute;
    left: 2rem;
    right: 14.5rem;
    bottom: 1.375rem;
    @include medium-down {
        left: 1rem;
        right: 12.5rem;
        bottom: 1.25rem;
    }
    z-index: 11;

    &:hover {
        .video-seeker-track {
            background-color: rgba($white, 0.5);
        }
    }
}
.video-seeker-track {
    position: absolute;
    left: 0;
    right: 0;
    top: calc(50% - 0.25rem);
    height: 0.5rem;
    border-radius: 50px;
    overflow: hidden;
    background-color: rgba($white, 0.2);
    transition: background-color 0.3s;
    z-index: -1;
}
.video-seeker-track-played {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: $yellow-color;
}
.video-seeker {
    width: 100%;
    -webkit-appearance: none;
    background: transparent;
    cursor: pointer;
}

.video-navigation-controls {
    display: flex;
    position: absolute;
    bottom: 1rem;
    right: 1.25rem;
    z-index: 11;
    @include medium-down {
        right: 1rem;
    }

    button {
        @include reset-button;
        width: 2rem;
        height: 2rem;
        margin: 0 0.5rem;
        @include medium-down {
            width: 1.75rem;
            height: 1.75rem;
            margin: 0 0.375rem;
        }
        border-radius: 50%;
        font-size: 0;
        background-color: $white;
        background-size: 50% 50%;
        background-position: center;
        background-repeat: no-repeat;

        &.video-fullscreen-button {
            background-image: url('../../assets/images/icons/ic-fullscreen.svg');
            &.is-fullscreen {
                background-image: url('../../assets/images/icons/ic-fullscreen-off.svg');
            }
        }
        &.video-audio-button {
            background-image: url('../../assets/images/icons/ic-unmute.svg');
            &.is-muted {
                background-image: url('../../assets/images/icons/ic-mute.svg');
            }
        }
        &.video-arrow.is-prev {
            background-image: url('../../assets/images/icons/ic-chevron-left-black.svg');
        }
        &.video-arrow.is-next {
            background-image: url('../../assets/images/icons/ic-chevron-right-black.svg');
        }

        &[disabled] {
            opacity: 0.25;
            cursor: not-allowed;
        }
    }
}
.video-backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0;
    background-color: rgba(#000, 0.9);
    transition: opacity 0.3s;
}
.video-play-button {
    @include reset-button;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 0;
    width: 8rem;
    height: 8rem;
    @include small-only {
        width: 4rem;
        height: 4rem;
    }
    transform: translate(-50%, -50%);
    z-index: 10;
    transition: transform 0.3s;

    &:active {
        transform: translate(-50%, -50%) scale(0.9);
    }

    &.is-playing {
        width: 100%;
        height: 100%;

        &::after {
            content: none;
        }
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        border-radius: 50%;
        background-image: url('../../assets/images/icons/ic-play-white.svg');
        background-size: 70% 70%;
        background-position: center;
        background-repeat: no-repeat;
        transition: background-color 0.3s;
    }
    &:hover {
        &::after {
            background-color: rgba($black, 0.3);
        }
    }
}

.video-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 3rem;
    background-color: $white;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    // transition: opacity 0.3s, visibility 0.3s;
    z-index: 7;

    &.is-open {
        opacity: 1;
        visibility: visible;
    }

    &.is-centered {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &.is-image {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
}
.video-overlay-items-wrapper {
    height: calc(100% - 6rem);
}
.video-overlay-item {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 1rem;
    background-color: $light-gray;
    border-radius: calc(#{$global-radius} / 2);

    &::after {
        content: '';
        display: block;
        padding-bottom: 60%;
    }
}

.video-notes-background {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 8rem;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
}
.video-notes {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 3rem;
    display: flex;
    align-items: center;
    gap: 1.5rem;
}
.video-notes-sets {
    color: $white;
    font-weight: 800;
    font-size: 1.5rem;
    white-space: nowrap;
    padding: 1rem 1.5rem;
    border-radius: 0 30px 30px 0;
    @include medium-down {
        padding: 0.75rem 1rem;
        font-size: 1rem;
    }
}
.video-notes-title {
    color: $white;
    margin-bottom: 0;
    font-size: 1.5rem;
    @include medium-down {
        font-size: 1rem;
    }
}
.video-notes-progress {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
    width: 10vw;
    font-size: 300%;
    font-weight: 800;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.2) 20%, rgba(255, 255, 255, 0) 70%);
}

.video-close {
    @include reset-button;
    position: absolute;
    top: 2rem;
    right: 2rem;
    @include medium-down {
        top: 0.75rem;
        right: 0.75rem;
    }
    display: inline-block;
    width: 3rem;
    height: 3rem;
    font-size: 0;
    border-radius: $global-radius;
    background-image: url('../../assets/images/icons/ic-cross-white.svg');
    background-size: 40% 40%;
    background-position: center;
    background-repeat: no-repeat;
    transition: background-color 0.3s;
    z-index: 11;
}

.video-skip-button {
    display: flex;
    align-items: center;
    gap: 1rem;
    position: absolute;
    right: 1.75rem;
    bottom: 5rem;
    z-index: 11;

    & > button {
        padding: 0.625rem 1rem;
        border-radius: $global-radius;
        font-weight: 600;
        border: none;
        background-color: $white;
        cursor: pointer;
    }

    @include medium-down {
        right: 1rem;

        & > button {
            font-size: 0.75rem;
        }
    }
}

@include medium-down {
    .video-wrapper {
        width: 100%;
        border-radius: 0;
        max-height: 100%;
    }
}
