@import "../../../../styles/settings";
@import "../../../../styles/mixins";

.form-select-wrapper {
    position: relative;

    &.is-reversed {
        .form-select-dropdown {
            bottom: 100%;
        }
    }

}
.form-select-value {
    &.is-open {
        border-color: $yellow-color;
    }
    &.is-placeholder {  
        font-weight: 400;
        color: rgba($body-color, .4);
    }
}
.form-select-dropdown {
    position: absolute;
    left: 0;
    right: 0;
    overflow: hidden;
    background-color: $white;
    box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.12);
    z-index: 1;

    input {
        display: block;
        width: 100%;
        font-size: 1rem;
        padding: 1rem;
        color: $body-color;
        border: none;
    }
}
.form-select-dropdown-menu {
    @include reset-list;
    margin-bottom: 1rem;
    max-height: 15rem;
    overflow: auto;

    li {
        cursor: pointer;
        font-size: 1rem;
        color: $body-color;
        padding: .75rem 1rem;
        border: 0px solid rgba($light-gray, .5);
        border-top-width: 1px;

        &:last-child {
            border-bottom-width: 1px;
        }

        &.is-active {
            background-color: $light-gray;
        }
        &.is-selected {
            background-color: $yellow-color;
        }
    }
}
.form-select-toggler, .form-select-cancel {
    position: absolute;
    top: calc(50% - 1rem);
    right: 1rem;
    width: 2rem;
    height: 2rem;
    border: none;
    font-size: 0;
    border-radius: 50%;
    background-color: transparent;
    background-image: url('../../../../assets/images/icons/ic-chevron-down-black.svg');
    background-size: 40% 40%;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: background-color .3s, transform .3s;

    &.is-open {
        transform: rotate(180deg);
    }

    &:hover {
        background-color: rgba($light-gray, .3);
    }
}
.form-select-cancel {
    right: 3.125rem;
    font-size: 1rem;
    background: none;
    color: $medium-gray;
}