@import '../../../styles/settings';

.forward-button {
    display: inline-flex;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1;
    white-space: nowrap;
    color: $black;
    cursor: pointer;
    transition: color 0.3s;

    svg {
        stroke: $black;
        width: .875rem;
        height: .875rem;
        margin-left: .375rem;
        transition: stroke .3s;
    }

    &:hover {
        color: $dark-gray;

        svg {
            stroke: $dark-gray;
        }
    }
}
