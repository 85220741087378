@import "../../../styles/settings";
@import "../../../styles/mixins";

.footer {
    background-color: $black;
    text-align: center;
}

.footer-logo {
    display: inline-block;
    max-width: 13.5rem;
    margin-right: auto;
}

.footer-nav {
    @include reset-list;

    li a {
        color: $white;

        &:hover {
            text-decoration: underline;
        }
    }
}

@include small-only {
    
    .footer-nav {
        li:not(:last-child) {
            margin-bottom: 1rem;
        }
    }
    
}

@include medium-down {
    
    .footer {
        padding: 3rem 1rem;
        font-size: .875rem;
    }
    .footer-logo {
        margin-bottom: 2rem;
    }
    
}

@include medium {
    
    .footer-nav {
        li {
            display: inline-block;
            margin: {
                left: -2px;
                right: 2em;
            }
        }
    }
    
}

@include large {
    
    .footer {
        padding: 2rem 2.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .footer-nav {
        margin-right: 2rem;
    }
    
}