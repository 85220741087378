@import "../../../../styles/settings";

.form-field-wrapper {
    position: relative;
    margin-bottom: 1rem;

    &.is-invalid {
        .form-input-text {
            border-color: $alert-color;
            background-color: rgba($alert-color, .05);
        }
    }
}
.form-error-messages {
    font-size: 0.75rem;
    color: $alert-color;
    text-align: right;
}