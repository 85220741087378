@import '../../../../styles/settings';

$radio-colors: (
    'yellow': $yellow-color,
    'pink': $pink-color,
    'blue': $blue-color,
    'purple': $purple-color,
);

.radio-input-wrapper {
    &:not(:last-child) {
        border-bottom: 1px solid $light-gray;
    }

    input[type='radio'] {
        position: absolute;
        left: -2000px;
        width: 0;
        height: 0;
        visibility: hidden;
    }
}

.radio-input {
    position: relative;
    display: flex;
    align-items: center;
    padding: 1.125rem 0;
    padding-right: 4rem;
    cursor: pointer;
    font-weight: 700;
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 100%;
        opacity: 0;
        transition: opacity 0.3s, left 0.3s;
        z-index: -1;
    }

    &::after {
        content: '';
        position: absolute;
        top: calc(50% - 0.5rem);
        right: 1.75rem;
        height: 1rem;
        width: 1rem;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0;
        transform: scale(0.5);
        transition: opacity 0.3s, transform 0.3s;
    }

    &:not(.is-disabled):hover {
        &::before {
            left: 0;
            opacity: 1;
        }
    }

    &.is-active {
        &::before {
            left: 0;
            opacity: 1;
        }
        &::after {
            opacity: 1;
            transform: scale(1);
        }

        .radio-input-radio::after {
            opacity: 1;
            transform: scale(1);
        }
    }

    &.is-disabled {
        opacity: 0.375;
        cursor: not-allowed;
    }

    @each $name, $color in $radio-colors {
        &.is-#{$name} {
            &::before {
                background: linear-gradient(270deg, rgba($color, 0.2) 38.18%, rgba(254, 239, 239, 0) 99%);
            }
            &::after {
                background-image: url('../../../../assets/images/icons/ic-check-#{$name}.svg');
            }

            .radio-input-radio::after {
                background-color: $color;
            }
        }
    }
}
.radio-input-radio {
    position: relative;
    display: inline-block;
    min-width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    border: 1px solid $black;
    margin-right: 1rem;

    &::after {
        content: '';
        position: absolute;
        top: 20%;
        bottom: 20%;
        right: 20%;
        left: 20%;
        border-radius: 50%;
        opacity: 0;
        transform: scale(0.8);
        transition: opacity 0.3s, transform 0.3s;
    }
}
.radio-input-image {
    display: inline-block;
    width: 2.375rem;
    height: 2.375rem;
    margin-right: 1rem;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
