@import '../../styles/settings';

.membership {
    color: rgb(47, 51, 69);
    font-family: Montserrat;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: rem-calc(32);

}

.membership-container {
    display: flex;
    flex-direction: column;
}

 .membership-title {
    font-size: rem-calc(50);
    text-transform: uppercase;
    font-weight: 800;
}

.membership-subtitle {
    font-size: rem-calc(20);
    font-weight: 600;
}

.membership-hint {
    font-size: rem-calc(14);
    font-weight: 400;
}

.membership-prices-container {
    display: flex;
    gap: rem-calc(20);
    margin-top: rem-calc(20);
}

.membership-prices-card {
    min-width: rem-calc(326);
    padding: rem-calc(20);
    display: flex;
    flex-direction: column;
    border-radius: rem-calc(20);
    overflow: hidden;
    background: linear-gradient(rgb(254, 239, 239), rgb(254, 239, 239)) !important;
}

.membership-prices-favourite {
    background: linear-gradient(rgb(229, 162, 160), rgb(229, 162, 160)) !important;
}

.membership-card-button {
    text-align: center;
    font-weight: 800 !important;
    letter-spacing: 0 !important;
    font-size: rem-calc(19) !important;
}

.membership-card-title-container {
    display: flex;
    gap: 0.5rem;
    align-items: center;
 margin-bottom:rem-calc(10); 
    justify-content: space-between;

    & > hr {
        height: 2px;
        width: rem-calc(100);
        background: rgb(47, 51, 69);
    }
}

.membership-card-favourite {
    overflow: hidden;
    max-width: unset;
    width: fit-content;
    background-color: rgb(246, 217, 143);
    border-radius: rem-calc(20);
    padding: rem-calc(5) rem-calc(15);
    font-weight: 700;
    font-size: rem-calc(15);
}

.membership-card-title {
    margin: 0;
    font-weight: 800;
    font-size: rem-calc(19);
}

.membership-card-price {
    margin: 0;
    font-weight: 700;
    margin-top: auto;
    font-size: rem-calc(32);
}

.membership-card-discount {
   text-decoration: line-through; 
}

.membership-card-savings {
    margin: 0;
    font-weight: 400;
    font-size: rem-calc(15);
    margin-bottom:rem-calc(10); 
 }

@include medium-down {
    .membership-title {
        font-size: rem-calc(32);
    }
    
    .membership-subtitle {
        font-size: rem-calc(16);
    }
    
    .membership-hint {
        font-size: rem-calc(12);
    }

    .membership-prices-container {
        flex-direction: column;
    }
}

