@import "../../../styles/settings";

.title-table {
    background-color: $white;
    box-shadow: 0px 15px 50px rgba(47, 51, 69, 0.1);
    border-radius: $global-radius;
    padding-bottom: 3rem;
}
.title-table-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: .75rem 2.5rem;

    .icon-title {
        margin: 1rem 0;
    }
}
.title-table-wrapper {
    max-width: 100%;
    overflow: auto;
}
.title-table-table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;

    th {
        padding-left: .5rem;
        padding-right: .5rem;

        &:first-of-type {
            padding-left: 2.5rem;
        }
        &:last-of-type {
            padding-right: 2.5rem;
        }
    }

    thead {
        background-color: $lightest-gray;
        border-bottom: 1px solid $medium-gray;
        font-size: .875rem;

        th {
            padding-top: 1.25rem;
            padding-bottom: 1.25rem;
        }
    }

    tbody {
        font-size: .875rem;

        th {
            font-weight: 400;
            padding-top: 1rem;
            padding-bottom: 1rem;
            border-bottom: 1px solid $light-gray;
        }

        tr {
            &:last-of-type {
                th {
                    border-bottom: none;
                }
            }
        }
    }
}