@import "../../styles/settings";

// .login-content {
//     min-height: calc(100vh - #{rem-calc(221)});
//     display: flex;
//     flex-wrap: wrap;
//     flex-direction: column;
//     justify-content: center;
//     padding-bottom: 6rem;
// }

.login-controls {
    display: flex;
    align-items: center;
    margin-top: 2rem;
}

@include small-only {
    
    .login-controls {
        flex-direction: column;

        & > .button.is-link {
            order: 1;
            margin-top: 2rem;
        }
    }
    
}

@include medium {
    
    .login-content {
        min-height: calc(100vh - #{rem-calc(221)});
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding-bottom: 6rem;

        form {
            width: 100%;
        }
    }
    .login-controls {
        justify-content: space-between;
    }
    
}
