@import '../../styles/settings';

@mixin thumb {
    border: 3px solid $white;
    height: 1.25rem;
    width: 1.25rem;
    border-radius: 50%;
    background: darken($yellow-color, 30%);
    cursor: pointer;
}

/* Special styling for WebKit/Blink */
input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    @include thumb;
}

/* All the same stuff for Firefox */
input[type='range']::-moz-range-thumb {
    @include thumb;
}

/* All the same stuff for IE */
input[type='range']::-ms-thumb {
    @include thumb;
}
