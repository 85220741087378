@import '../../styles/settings';

.menu-detail-procedure-title {
    font-size: 1.25rem;
}
.menu-detail-procedure {
    white-space: pre-wrap;
}

.menu-detail-heart-wrapper {
    display: inline-flex;
    align-items: center;
    margin-top: -1rem;
    margin-bottom: 1.5rem;
    font-size: 0.875rem;
}
.menu-detail-heart {
    display: inline-block;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 0.75rem;
    border-radius: 50%;
    border: none;
    background-color: $lightest-gray;
    background-image: url('../../assets/images/icons/ic-heart.svg');
    background-size: 55%;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: background-color 0.3s;

    &.is-active {
        background-image: url('../../assets/images/icons/ic-heart-active.svg');
    }

    &:hover {
        background-color: $semi-gray;
    }
}

@include small-only {
    .menu-detail-procedure-title {
        text-align: center;
        margin-bottom: 1.5rem;
    }
    .menu-detail-procedure {
        text-align: center;
    }
}
