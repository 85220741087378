@import '../../../styles/settings';

.dashboard {
    padding-top: 6.375rem;
}
.dashboard-container {
    position: relative;
}

@include small-only {
    .dashboard-container {
        min-height: calc(100vh - #{rem-calc(401)});
    }
}

@include medium-down {
    .dashboard {
        padding-top: 4.75rem;
        padding-bottom: 4.0625rem;
    }
}

@include medium {
    .dashboard-container {
        min-height: calc(100vh - #{rem-calc(327)});
    }
    .dashboard-grid {
        display: flex;
        flex-wrap: wrap;
    }
    .dashboard-content {
        flex-grow: 1;
    }
}

@include large {
    .dashboard-container {
        min-height: calc(100vh - #{rem-calc(203)});
    }
    .dashboard-grid {
        flex-wrap: nowrap;
    }
    .dashboard-side-text {
        width: 30%;
        min-width: 27rem;
        padding-right: 3rem;
    }
}
