@import '../../../styles/settings';

.tutorial-video {
    margin: 2.5rem 3rem 3rem;
    border-radius: $global-radius;
    overflow: hidden;
    @include small-only {
        background-color: black;
        padding: 0 1rem;
    }
}
.tutorial-video-wrapper {
    display: flex;

    video {
        outline: none;
    }
}
