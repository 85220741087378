@import '../../styles/settings';

.overview-text {
    margin-bottom: 2rem;

    p {
        font-size: 0.875rem;
        margin-bottom: 2rem;
    }
}

.overview-news-item {
    outline: none;

    h4 {
        font-size: 0.875rem;
        font-weight: 700;
        margin-bottom: 0.75rem;
    }

    p {
        font-size: 0.875rem;
        margin-bottom: 0;
    }
}

.overview-news-title {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    hr {
        flex-grow: 1;
        margin-left: 1rem;
    }
}

@include small-only {
    .overview-text {
        text-align: center;
    }

    .overview-list-wrapper {
        .overview-list {
            &:not(:last-of-type) {
                margin-bottom: 2rem;
            }
        }
    }
}

@include medium {
    .overview-text {
        padding-top: 3rem;
    }
    .overview-list-wrapper {
        display: flex;
        align-items: flex-start;
        margin: 0 -.9375rem;

        .overview-list {
            flex-grow: 1;
            margin: 0 .9375rem;
        }
    }
}

@include large {
    .overview-news-item {
        p {
            padding-right: 10%;
        }
    }
}
