@import '../../styles/settings';
@import '../../styles/mixins';

.overview-list {
    display: flex;
    flex-direction: column;
    border: 20px solid $pink-color;
    border-radius: $global-radius;
    background-color: rgba($pink-color, 0.2);
    box-shadow: 0px 15px 50px rgba(47, 51, 69, 0.1);

    &.is-blue {
        border-color: $blue-color;
        background-color: rgba($blue-color, 0.2);

        .overview-list-list {
            li {
                &:not(:last-child) {
                    border-color: $blue-color;
                }
            }
        }
        .overview-list-list-item {
            svg {
                stroke: $blue-color;
            }
        }
    }
    &.is-yellow {
        border-color: $yellow-color;
        background-color: rgba($yellow-color, 0.2);

        .overview-list-list {
            li {
                &:not(:last-child) {
                    border-color: $yellow-color;
                }
            }
        }
        .overview-list-list-item {
            svg {
                stroke: $yellow-color;
            }
        }
    }
    &.is-pink {
        border-color: $pink-color;
        background-color: rgba($pink-color, 0.2);

        .overview-list-list {
            li {
                &:not(:last-child) {
                    border-color: $pink-color;
                }
            }
        }
        .overview-list-list-item {
            svg {
                stroke: $pink-color;
            }
        }
    }
    &.is-purple {
        border-color: $purple-color;
        background-color: rgba($purple-color, 0.2);

        .overview-list-list {
            li {
                &:not(:last-child) {
                    border-color: $purple-color;
                }
            }
        }
        .overview-list-list-item {
            svg {
                stroke: $purple-color;
            }
        }
    }

    &.is-loading {
        .overview-list-list-item > div {
            height: rem-calc(21);
            width: 80%;
            border-radius: $global-radius;
            background-color: rgba($black, 0.075);
        }
    }
}
.overview-list-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $white;
    border-radius: calc(#{$global-radius} / 1.5);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    color: $black;
    font-size: 1.25rem;
    font-weight: 800;
    padding: 1rem 1.5rem;
    transition: box-shadow 0.3s;
    z-index: 1;

    i {
        display: inline-block;
        min-width: 1.75rem;
        height: 1.75rem;
        margin-right: 1rem;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    svg {
        margin-left: auto;
        min-width: 0.75rem;
        height: 1.125rem;
        stroke: $black;
    }

    &.is-wrapped {
        flex-wrap: wrap;
        gap: 0.25rem;
    }
}
a.overview-list-button {
    &:hover {
        box-shadow: none;
    }
}

.overview-list-list {
    @include reset-list;
    padding: 1rem;
    padding-top: 0.5rem;
    margin-top: auto;

    li {
        &:not(:last-child) {
            border-bottom: 1px solid $pink-color;
        }
    }
}
.overview-list-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.875rem;
    font-weight: 700;
    padding: 1rem 0;
    padding-right: 0.5rem;
    transition: color 0.3s;
    width: 100%;
    background-color: transparent;
    border: none;
    cursor: pointer;

    svg {
        width: 0.75rem;
        height: 0.75rem;
        stroke: $pink-color;
    }

    &:hover {
        color: $dark-gray;
    }
}

.overview-list-image {
    display: flex;
    position: relative;
    border-radius: 0 0 calc(#{$global-radius} / 2) calc(#{$global-radius} / 2);
    overflow: hidden;
    margin-top: -2rem;
    flex-grow: 1;

    & > img {
        object-fit: cover;
        object-position: center;
    }
}
.overview-list-badge {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    background-color: $white;
    border-radius: calc(#{$global-radius} / 2);
    padding: 0.25rem 0.75rem;
}

@include small-only {
    .overview-list-button {
        font-size: 1rem;
    }
}
