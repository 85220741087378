@import "./global/global-utils";

/* --------------
- Global
----------------- */
$global-width: rem-calc(1420);
$global-lineheight: 1.5;
$global-radius: rem-calc(50);
$global-gap: 1.875rem;
$global-gap-small: 1.875rem;

/* --------------
- Breakpoints
----------------- */
$breakpoint-small: 0;
$breakpoint-medium: 640px;
$breakpoint-large: 1024px;

/* --------------
- Colors
----------------- */
$yellow-color: #F6D98F;
$pink-color: #FAB0AD;
$blue-color: #77C7EC;
$purple-color: #C4AFFF;
$black: #2F3345;
$white: #fff;
$lightest-gray: #F4F4F5;
$light-gray: #EBEBED;
$semi-gray:#D5D6DA;
$medium-gray:#9799A2;
$dark-gray: #4F556C;
$alert-color: #DD4A4A;
$warning-color: #FF655F;
$success-color: #28a745;
$body-color: $black;
$body-background: $white;
$box-shadow: 0px 15px 50px rgba(47, 51, 69, 0.1);

/* --------------
- Typography
----------------- */
$body-font-family: 'Montserrat', sans-serif;
$header-color: $black;
$header-font-family: 'Montserrat', sans-serif;
$header-font-weight: 800;
$header-lineheight: 1.2;
$header-h1-fontsize: (
    'large': 48,
    'medium': 36,
    'small': 32,
);
$header-h2-fontsize: (
    'large': 36,
    'medium': 32,
    'small': 28,
);
$header-h3-fontsize: (
    'large': 30,
    'medium': 28,
    'small': 24,
);
$header-h4-fontsize: (
    'large': 16,
    'medium': 16,
    'small': 16,
);

/* --------------
- Helper mixins and function
----------------- */
@mixin large {
    @media screen and (min-width: #{$breakpoint-large}) {
        @content;
    }
}
@mixin medium {
    @media screen and (min-width: #{$breakpoint-medium}) {
        @content;
    }
}
@mixin medium-only {
    @media screen and (min-width: #{$breakpoint-medium}) and (max-width: #{$breakpoint-large - 1px}) {
        @content;
    }
}
@mixin medium-down {
    @media screen and (max-width: #{$breakpoint-large - 1px}) {
        @content;
    }
}
@mixin small-only {
    @media screen and (max-width: #{$breakpoint-medium - 1px}) {
        @content;
    }
}
