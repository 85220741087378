@import '../../../styles/settings';
@import '../../../styles/mixins';

$training-colors: (
    'yellow': $yellow-color,
    'pink': $pink-color,
    'blue': $blue-color,
    'purple': $purple-color,
    'gray': $dark-gray,
    'grey': $dark-gray,
    'black': $black,
);

.training-row {
    display: flex;
    align-items: flex-start;
    padding: 1.25rem 0;

    &:not(:last-child) {
        border-bottom: 1px solid $light-gray;
    }

    @each $name, $color in $training-colors {
        &.is-#{$name} {
            .training-row-spec li strong {
                color: $color;
            }
        }
    }
}
.training-row-image {
    min-width: 13rem;
    height: 7.5rem;
    position: relative;
    margin-right: 2rem;
    border-radius: calc(#{$global-radius} / 2);
    @include small-only {
        border-radius: calc(#{$global-radius} / 3);
    }
    background-color: $light-gray;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &.is-icon {
        opacity: 0.4;
        background-size: 40% 40%;
    }

    span {
        display: inline-block;
        position: absolute;
        top: 0.625rem;
        right: 0.625rem;
        width: 2.25rem;
        height: 2.25rem;
        line-height: 2.25rem;
        text-align: center;
        border-radius: 50%;
        font-size: 0.875rem;
        font-weight: 800;

        @each $name, $color in $training-colors {
            &.is-#{$name} {
                background-color: $color;
            }
        }
    }
}
.training-row-play {
    @include reset-button;
    position: absolute;
    bottom: 0.375rem;
    right: 0.375rem;
    @include small-only {
        bottom: 0.175rem;
        right: 0.175rem;
    }
    font-size: 0;
    width: 1.75rem;
    height: 1.75rem;
    border-radius: $global-radius;
    background-color: $black;
    background-image: url('../../../assets/images/icons/ic-play-white.svg');
    background-size: 80% 80%;
    background-position: center;
    background-repeat: no-repeat;
}
.training-row-content {
    flex-grow: 1;

    h3 {
        font-size: 1.25rem;
    }
}
.training-row-spec {
    @include reset-list;
}
.training-row-description {
    position: relative;
    overflow: hidden;
    transition: height 0.3s;

    h4 {
        margin-bottom: 0.5rem;
    }

    p {
        font-size: 0.875rem;
    }
}
.training-row-toggle {
    border: none;
    background-color: transparent;
    font-size: 0;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background-image: url('../../../assets/images/icons/ic-chevron-down-black.svg');
    background-size: 1rem 1rem;
    background-position: center;
    background-repeat: no-repeat;
    transition: background-color 0.3s, transform 0.3s;
    cursor: pointer;

    &.is-open {
        transform: rotate(180deg);
    }
}

@include small-only {
    .training-row-image {
        min-width: 5.625rem;
        height: 5.625rem;
        margin-right: 1.5rem;

        span {
            width: 1.75rem;
            height: 1.75rem;
            line-height: 1.75rem;
        }
    }
    .training-row-content {
        width: calc(100% - 7.5rem);
    }
}

@include medium-down {
    .training-row {
        flex-wrap: wrap;
    }
    .training-row-content {
        position: relative;
        padding-right: 2.5rem;
        width: calc(100% - 15rem);
    }
    .training-row-description {
        height: 0;
        width: 100%;

        & > div {
            padding-top: 1rem;
        }
    }
    .training-row-toggle {
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

@include large {
    .training-row-content {
        padding-right: 2rem;
        margin-top: 1rem;
    }
    .training-row-description {
        flex-shrink: 0;
        width: 28rem;
        padding-right: 4rem;
        margin-top: 1rem;
        height: 5.5rem;
    }
    .training-row-toggle {
        position: absolute;
        top: calc(50% - 0.75rem);
        right: 0;
    }
    .training-row-toggle {
        &:hover {
            background-color: $lightest-gray;
        }
    }
}
