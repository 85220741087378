@import '../../../styles/settings';

.day-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0;

    &:not(:last-of-type):not(.is-head) {
        border-bottom: 1px solid $light-gray;
    }

    &.is-head {
        padding: 0;
    }
}

.day-menu-item-switch {
    display: inline-block;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    font-size: 0;
    background-color: $white;
    border: none;
    background-image: url('../../../assets/images/icons/ic-switch.svg');
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    z-index: 10;
    transition: background-color 0.3s, opacity 0.3s;

    &:hover {
        background-color: $yellow-color;
    }
}

.day-menu-item-image {
    border-radius: calc(#{$global-radius} / 2);
    background-color: $light-gray;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &::after {
        content: '';
        display: block;
        padding-bottom: 55%;
    }
}
.day-menu-item-content {
    display: none;
}

@include small-only {
    .day-menu {
        display: block;
        overflow: hidden;
        max-height: 3.75rem;
        transition: max-height 0.3s;

        &.is-head {
            display: none;
        }

        &.is-open {
            max-height: 45rem;

            .day-menu-day::after {
                transform: rotate(180deg);
            }
            .day-menu-item > a {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }
    .day-menu-item {
        display: flex;
        align-items: center;

        & > a {
            display: flex;
            align-items: flex-start;
            opacity: 0;
            transform: translateY(-1rem);
            margin-bottom: 0.75rem;
            transition: opacity 0.3s, transform 0.3s;
        }
    }
    .day-menu-day {
        position: relative;
        padding: 0.5rem 0;
        margin-bottom: 0.5rem;
        z-index: 1;

        &::after {
            content: '';
            position: absolute;
            top: calc(50% - 0.375rem);
            width: 0.75rem;
            height: 0.75rem;
            right: 1rem;
            background-image: url('../../../assets/images/icons/ic-chevron-down-black.svg');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            transition: transform 0.3s;
        }
    }
    .day-menu-item-content {
        display: block;
        margin-left: 1rem;
        flex-grow: 1;

        h5 {
            margin-bottom: 0;
        }
    }
    .day-menu-item-image {
        min-width: 4.5rem;
        // border-radius: calc(#{$global-radius} / 4);
        &::after {
            padding-bottom: 100%;
        }
    }
    .day-menu-item-switch {
        flex-shrink: 0;
        margin-left: 1rem;
    }
}

@include medium {
    .day-menu {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.is-active {
            .day-menu-day {
                opacity: 1;
            }
            .day-menu-item {
                opacity: 1;
            }
        }

        &:hover {
            .day-menu-day {
                opacity: 1;
            }
        }
    }
    .day-menu-day {
        flex-grow: 1;
        margin-bottom: 0;
        opacity: 0.4;
        transition: opacity 0.3s;
    }

    .day-menu-item {
        position: relative;
        width: 16%;
        padding: 0.5rem;
        opacity: 0.4;
        transition: opacity 0.3s;

        &:hover {
            opacity: 1;

            .day-menu-item-switch {
                opacity: 1;
            }
        }

        & > a {
            cursor: pointer;
        }

        &.is-head {
            font-size: 0.875rem;
            font-weight: 600;
            text-align: center;
            opacity: 1;
            cursor: default;
        }
    }

    .day-menu-item-switch {
        position: absolute;
        top: 1rem;
        right: 1rem;
        opacity: 0;
    }
}
