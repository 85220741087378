@import '../../../styles/settings';
@import '../../../styles/mixins';

.recipe-item {
    @include reset-button;
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;
    padding: 0.75rem;
    cursor: default;
    @include small-only {
        padding: 0.75rem 0;
    }
    border-radius: 1rem;
    transition: background-color 0.3s;
    width: 100%;

    @include medium {
        &:not([disabled]):hover {
            background-color: $light-gray;
        }
    }

    &[disabled] {
        opacity: 0.5;
        cursor: progress;
    }

    &.is-favorite {
        .recipe-item-image {
            position: relative;
            &::before {
                content: '';
                position: absolute;
                bottom: 0.25rem;
                right: 0.25rem;
                display: inline-block;
                flex-shrink: 0;
                width: 1.5rem;
                height: 1.5rem;
                border-radius: 50%;
                background-color: $white;
                background-image: url('../../../assets/images/icons/ic-heart-active.svg');
                background-size: 55%;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
    }
}

.recipe-item-content {
    text-align: left;
    margin-left: 1rem;

    h5 {
        margin-bottom: 0;
        margin-right: 1rem;
    }
}

.recipe-item-image {
    border-radius: calc(#{$global-radius} / 4);
    background-color: $light-gray;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &::after {
        content: '';
        display: block;
        padding-bottom: 55%;
    }
    min-width: 4.5rem;
    // border-radius: calc(#{$global-radius} / 4);
    &::after {
        padding-bottom: 100%;
    }
}

.recipe-item-button {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
    margin-left: auto;
    font-size: 0;
    border-radius: 50%;
    border: 2px solid $yellow-color;
    background-color: $white;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: $yellow-color;
    }
}
