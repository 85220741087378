.icon-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1rem;

    h3 {
        font-size: 1.25rem;
        margin-bottom: 0;
    }

    i {
        min-width: 2.75rem;
        height: 2.75rem;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin-right: .75rem;

        &.is-small {
            min-width: 1.625rem;
            height: 1.625rem;
        }
    }
}