@import "../../../styles/settings";

.tagline {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 800;
    letter-spacing: 0.15em;

    &.is-small {
        font-size: .875rem;
    }
}