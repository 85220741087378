@import '../../../styles/settings';
@import '../../../styles/mixins';

.notification {
    position: fixed;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    background-color: $yellow-color;
    font-size: 1rem;
    font-weight: 600;
    overflow: hidden;
    color: $white;
    top: 2rem;
    left: 50%;
    transform: translateX(-50%);
    padding: 1rem;
    width: 35rem;
    max-width: calc(100vw - 1.5rem);
    border-radius: 1rem;
    z-index: 21;
    @include small-only {
        font-size: 0.875rem;
        top: 0;
        max-width: 100%;
        border-radius: 0;
    }

    &.is-error {
        background-color: $alert-color;
    }
    &.is-success {
        background-color: $success-color;
    }
}

.notification-close {
    @include reset-button;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background-color: rgba($white, 0.2);
    font-size: 1.25rem;
    color: $white;
    font-weight: 400;
    margin-left: 1rem;
    width: 2rem;
    height: 2rem;
    border-radius: 0.5rem;
}

.notification-progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    background-color: rgba($white, 0.5);
}
