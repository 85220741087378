@import '../../styles/settings';

.profile-setup {
    .pager {
        margin-bottom: 2.5rem;
    }

    h1 {
        margin-bottom: 3rem;
    }
}

.profile-setup-controls {
    display: flex;
    justify-content: space-between;
    padding: 1.75rem 0;

    .button {
        &:only-child {
            margin-left: auto;
        }
    }
}
