@import '../../styles/settings';

.meal-plan-menu-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
}

@include medium {
    .meal-plan-menu-header-controls {
        display: flex;
        align-items: center;
    }
}

@include small-only {
    .meal-plan-menu-header {
        display: block;
    }
    .meal-plan-menu-header-controls {
        text-align: center;
        width: 100%;
        margin-top: 1rem;

        .button {
            margin-bottom: 1rem;
        }
    }
}
